/* This example requires Tailwind CSS v2.0+ */
import React, { Fragment } from "react"
import { Popover, Transition } from "@headlessui/react"
import {
	BookmarkAltIcon,
	CalendarIcon,
	ChartBarIcon,
	CursorClickIcon,
	MenuIcon,
	PhoneIcon,
	RefreshIcon,
	ShieldCheckIcon,
	SupportIcon,
	ViewGridIcon,
	XIcon,
} from "@heroicons/react/outline"
import { ChevronDownIcon } from "@heroicons/react/solid"
import Link from "./Link"
import { phoneNumber } from "config"

const propertyCategories = [
	{
		name: "Alle Immobilien",
		href: "/immobilien",
		description: "Get a better understanding of where your traffic is coming from.",
		icon: ChartBarIcon,
	},
	{
		name: "Wohnungen",
		href: "/immobilien/wohnungen",
		description: "Speak directly to your customers in a more meaningful way.",
		icon: CursorClickIcon,
	},
	{
		name: "Häuser",
		href: "/immobilien/haueser",
		description: "Your customers' data will be safe and secure.",
		icon: ShieldCheckIcon,
	},
	{
		name: "Projekte",
		href: "/immobilien/projekte",
		description: "Connect with third-party tools that you're already using.",
		icon: ViewGridIcon,
	},
	{
		name: "Einfamilienhäuser in Tirol",
		href: "/immobilien/einfamilienhaueser-in-tirol",
		description: "Build strategic funnels that will drive your customers to convert",
		icon: RefreshIcon,
	},
]
const callsToAction = [{ name: phoneNumber, href: `tel:${phoneNumber}`, icon: PhoneIcon }]
const resources = [
	{
		name: "Leistungen",
		description: "Get all of your questions answered in our forums or contact support.",
		href: "#",
		icon: SupportIcon,
	},
	{
		name: "Wissen",
		description: "Learn how to maximize our platform to get the most out of it.",
		href: "#",
		icon: BookmarkAltIcon,
	},
	{
		name: "Kontakt",
		description: "See what meet-ups and other events we might be planning near you.",
		href: "#",
		icon: CalendarIcon,
	},
]

function classNames(...classes) {
	return classes.filter(Boolean).join(" ")
}

export default function Example() {
	return (
		<div className="relative bg-gray-50">
			<Popover className="relative bg-white shadow">
				{({ open }) => (
					<>
						<div className="max-w-7xl mx-auto px-4 sm:px-6">
							<div className="flex justify-between items-center py-6 md:justify-start md:space-x-10">
								<div className="flex justify-start lg:w-0 lg:flex-1">
									<Link href="/">
										<span className="sr-only">Workflow</span>
										<img
											className="h-8 w-auto sm:h-10"
											src="https://tailwindui.com/img/logos/workflow-mark-indigo-600.svg"
											alt=""
										/>
									</Link>
								</div>

								<div className="-mr-2 -my-2 md:hidden">
									<Popover.Button className="bg-white rounded-md p-2 inline-flex items-center justify-center text-gray-400 hover:text-gray-500 hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-indigo-500">
										<span className="sr-only">Open menu</span>
										<MenuIcon className="h-6 w-6" aria-hidden="true" />
									</Popover.Button>
								</div>

								<Popover.Group as="nav" className="hidden md:flex space-x-10">
									<Popover className="relative">
										{({ open }) => (
											<>
												<Popover.Button
													className={classNames(
														open ? "text-gray-900" : "text-gray-500",
														"group bg-white rounded-md inline-flex items-center text-base font-medium hover:text-gray-900 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500",
													)}
												>
													<span>Immobilien</span>
													<ChevronDownIcon
														className={classNames(
															open
																? "text-gray-600"
																: "text-gray-400",
															"ml-2 h-5 w-5 group-hover:text-gray-500",
														)}
														aria-hidden="true"
													/>
												</Popover.Button>

												<Transition
													show={open}
													as={Fragment}
													enter="transition ease-out duration-200"
													enterFrom="opacity-0 translate-y-1"
													enterTo="opacity-100 translate-y-0"
													leave="transition ease-in duration-150"
													leaveFrom="opacity-100 translate-y-0"
													leaveTo="opacity-0 translate-y-1"
												>
													<Popover.Panel
														static
														className="absolute -ml-4 mt-3 transform z-10 px-2 w-screen max-w-md sm:px-0 lg:ml-0 lg:left-1/2 lg:-translate-x-1/2"
													>
														<div className="rounded-lg shadow-lg ring-1 ring-black ring-opacity-5 overflow-hidden">
															<div className="relative grid gap-6 bg-white px-5 py-6 sm:gap-8 sm:p-8">
																{propertyCategories.map((item) => (
																	<Link
																		key={item.name}
																		href={item.href}
																		className="-m-3 p-3 flex items-start rounded-lg hover:bg-gray-50"
																	>
																		<item.icon
																			className="flex-shrink-0 h-6 w-6 text-indigo-600"
																			aria-hidden="true"
																		/>
																		<div className="ml-4">
																			<p className="text-base font-medium text-gray-900">
																				{item.name}
																			</p>
																			<p className="mt-1 text-sm text-gray-500">
																				{item.description}
																			</p>
																		</div>
																	</Link>
																))}
															</div>
															<div className="px-5 py-5 bg-gray-50 space-y-6 sm:flex sm:space-y-0 sm:space-x-10 sm:px-8">
																{callsToAction.map((item) => (
																	<div
																		key={item.name}
																		className="flow-root"
																	>
																		<Link
																			href={item.href}
																			className="-m-3 p-3 flex items-center rounded-md text-base font-medium text-gray-900 hover:bg-gray-100"
																		>
																			<item.icon
																				className="flex-shrink-0 h-6 w-6 text-gray-400"
																				aria-hidden="true"
																			/>
																			<span className="ml-3">
																				{item.name}
																			</span>
																		</Link>
																	</div>
																))}
															</div>
														</div>
													</Popover.Panel>
												</Transition>
											</>
										)}
									</Popover>

									<Link
										href="/leistungen"
										className="text-base font-medium text-gray-500 hover:text-gray-900"
									>
										Leistungen
									</Link>
									<Link
										href="/wissen"
										className="text-base font-medium text-gray-500 hover:text-gray-900"
									>
										Wissen
									</Link>
									<Link
										href="/kontakt"
										className="text-base font-medium text-gray-500 hover:text-gray-900"
									>
										Kontakt
									</Link>
								</Popover.Group>
								<div className="hidden md:flex items-center justify-end md:flex-1 lg:w-0">
									<Link
										href="/verkaufen"
										className="ml-8 whitespace-nowrap inline-flex items-center justify-center px-4 py-2 border border-transparent rounded-md shadow-sm text-base font-medium text-white bg-indigo-600 hover:bg-indigo-700"
									>
										Immobilie verkaufen
									</Link>
								</div>
							</div>
						</div>

						<Transition
							show={open}
							as={Fragment}
							enter="duration-200 ease-out"
							enterFrom="opacity-0 scale-95"
							enterTo="opacity-100 scale-100"
							leave="duration-100 ease-in"
							leaveFrom="opacity-100 scale-100"
							leaveTo="opacity-0 scale-95"
						>
							<Popover.Panel
								focus
								static
								className="absolute top-0 inset-x-0 z-10 p-2 transition transform origin-top-right md:hidden"
							>
								<div className="rounded-lg shadow-lg ring-1 ring-black ring-opacity-5 bg-white divide-y-2 divide-gray-50">
									<div className="pt-5 pb-6 px-5">
										<div className="flex items-center justify-between">
											<div>
												<img
													className="h-8 w-auto"
													src="https://tailwindui.com/img/logos/workflow-mark-indigo-600.svg"
													alt="Workflow"
												/>
											</div>
											<div className="-mr-2">
												<Popover.Button className="bg-white rounded-md p-2 inline-flex items-center justify-center text-gray-400 hover:text-gray-500 hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-indigo-500">
													<span className="sr-only">Close menu</span>
													<XIcon className="h-6 w-6" aria-hidden="true" />
												</Popover.Button>
											</div>
										</div>
										<div className="mt-6">
											<nav className="grid gap-y-8">
												{propertyCategories.map((item) => (
													<a
														key={item.name}
														href={item.href}
														className="-m-3 p-3 flex items-center rounded-md hover:bg-gray-50"
													>
														<item.icon
															className="flex-shrink-0 h-6 w-6 text-indigo-600"
															aria-hidden="true"
														/>
														<span className="ml-3 text-base font-medium text-gray-900">
															{item.name}
														</span>
													</a>
												))}
											</nav>
										</div>
									</div>
									<div className="py-6 px-5 space-y-6">
										<div className="grid grid-cols-2 gap-y-4 gap-x-8">
											{resources.map((item) => (
												<a
													key={item.name}
													href={item.href}
													className="text-base font-medium text-gray-900 hover:text-gray-700"
												>
													{item.name}
												</a>
											))}
										</div>
										<div>
											<Link
												href="/verkaufen"
												className="w-full flex items-center justify-center px-4 py-2 border border-transparent rounded-md shadow-sm text-base font-medium text-white bg-indigo-600 hover:bg-indigo-700"
											>
												Immobilie verkaufen
											</Link>
										</div>
									</div>
								</div>
							</Popover.Panel>
						</Transition>
					</>
				)}
			</Popover>
		</div>
	)
}
