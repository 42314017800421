import React from "react"

import isBrowser from "../utils/isBrowser"

import { NEXT_PUBLIC_GOOGLE_TAG_MANAGER_CONTAINER_ID } from "utils/envVariables"

const isGoogleTagManagerDataLayerAvailable = () => isBrowser() && !!window["dataLayer"]

export const pushEventToDataLayer = (event: any) => {
	if (isGoogleTagManagerDataLayerAvailable()) window["dataLayer"].push(event)
}

export const GoogleTagManagerScript = () => {
	if (!NEXT_PUBLIC_GOOGLE_TAG_MANAGER_CONTAINER_ID) return null

	return (
		<script
			type="text/javascript"
			dangerouslySetInnerHTML={{
				__html: `(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
        new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
        j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
        'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
        })(window,document,'script','dataLayer','${NEXT_PUBLIC_GOOGLE_TAG_MANAGER_CONTAINER_ID}')`,
			}}
		/>
	)
}

export const GoogleTagManagerIframe = () => {
	if (!NEXT_PUBLIC_GOOGLE_TAG_MANAGER_CONTAINER_ID) return null

	return (
		<noscript>
			<iframe
				title="GTM"
				src={`https://www.googletagmanager.com/ns.html?id=${NEXT_PUBLIC_GOOGLE_TAG_MANAGER_CONTAINER_ID}`}
				height="0"
				width="0"
				style={{ display: "none", visibility: "hidden" }}
			></iframe>
		</noscript>
	)
}
