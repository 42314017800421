import React from "react"
import { AppProps } from "next/app"

import Header from "components/Header"
import Footer from "components/Footer"

import isProduction from "utils/isProduction"
import { pushEventToDataLayer } from "components/GoogleTagManager"

import "styles/tailwind.css"

const CustomApp = (props: Props) => {
	const { Component, pageProps, err } = props

	// Workaround for https://github.com/zeit/next.js/issues/8592
	const modifiedPageProps = { ...pageProps, err }

	return (
		<>
			<Header />
			<div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
				<Component {...modifiedPageProps} />
			</div>
			<Footer />
		</>
	)
}

interface Props extends AppProps {
	err?: any
}

export function reportWebVitals({ id, name, label, value }) {
	// Only report web vitals in production
	if (isProduction()) {
		pushEventToDataLayer({
			event: "web-vitals",
			event_category: label === "web-vital" ? "Web Vitals" : "Next.js custom metric",
			event_action: name,
			// Google Analytics metrics must be integers, so the value is rounded.
			// For CLS the value is first multiplied by 1000 for greater precision
			// (note: increase the multiplier for greater precision if needed).
			event_value: Math.round(name === "CLS" ? value * 1000 : value),
			// The `id` value will be unique to the current page load. When sending
			// multiple values from the same page (e.g. for CLS), Google Analytics can
			// compute a total by grouping on this ID (note: requires `eventLabel` to
			// be a dimension in your report).
			event_label: id,
		})
	}
}

export default CustomApp
