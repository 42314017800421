import React from "react"
import NextLink, { LinkProps } from "next/link"

const Link = ({ href, as, children, ...props }: Props) => {
	return (
		<NextLink href={href} as={as}>
			<a {...props}>{children}</a>
		</NextLink>
	)
}

type Props = LinkProps &
	React.DetailedHTMLProps<React.AnchorHTMLAttributes<HTMLAnchorElement>, HTMLAnchorElement>

export default Link
