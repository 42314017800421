// Google Tag Manager/Analytics
export const NEXT_PUBLIC_GOOGLE_TAG_MANAGER_CONTAINER_ID =
	process.env.NEXT_PUBLIC_GOOGLE_TAG_MANAGER_CONTAINER_ID

// Misc
export const NEXT_PUBLIC_HOST =
	process.env.NEXT_PUBLIC_HOST ||
	(process.env.NEXT_PUBLIC_VERCEL_URL
		? `https://${process.env.NEXT_PUBLIC_VERCEL_URL}`
		: "http://localhost:3000")

export const NEXT_PUBLIC_BUILD_ID = process.env.NEXT_PUBLIC_BUILD_ID
